import {
  Avatar,
  Divider,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { blue, green, pink } from "@mui/material/colors";
import { AppColors } from "../../constants/colors";
import CustomListItemModel from "../../models/shared/customListItem";

interface Props {
  index: number;
  item: CustomListItemModel;
}

export const CustomListItem: React.FC<Props> = (props) => {
  return (
    <Grid item width={"100%"}>
      <ListItem
        sx={{
          background: props.index % 2 === 0 ? blue[50] : AppColors.LightGray,
        }}
      >
        <ListItemAvatar>
          <Avatar
            sx={{
              bgcolor: props.item.value
                ? props.index % 2 === 0
                  ? pink[500]
                  : green[500]
                : AppColors.Gray,
            }}
          >
            {props.item.icon}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography
              variant="button"
              color={!props.item.value ? AppColors.Gray : undefined}
            >
              {props.item.title}
            </Typography>
          }
          secondary={
            <Typography
              variant="subtitle2"
              color={!props.item.value ? AppColors.Gray : undefined}
            >
              {props.item.value ? props.item.value : "Not Available"}
            </Typography>
          }
        />
      </ListItem>
      <Divider sx={{ mt: 1, mb: 1 }} />
    </Grid>
  );
};

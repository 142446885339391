import { Box, Typography } from "@mui/material";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom";

export const AccountActivated = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Typography variant="h1">
        <CheckCircle color="success" fontSize="inherit" />
      </Typography>
      <Typography variant="h5">
        Your account has been activated successfully!
      </Typography>
      <Link
        to="/"
        style={{
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        Back to Home
      </Link>
    </Box>
  );
};

import { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
  Card,
  CardContent,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SignUpFormModel from "../../models/auth/signup";
import { ControlledTextField } from "../../components/shared/controlledTextField";
import { SignupFormSchema } from "../../schemas/forms/signup";
import { Link, useNavigate } from "react-router-dom";
import { EmailEligibilityFormSchema } from "../../schemas/forms/emailEligibility";
import { VerifyEmailEligibility as VerifySignUpEligibility } from "../../apis/auth/emailEligibity";
import { AppCtx } from "../../store/store";
import { SignUpUser } from "../../apis/auth/signup";
import SignUpEligibilityModel from "../../models/auth/emailEligibilityModel";

export default function SignUp() {
  const navigate = useNavigate();
  const { isLoading, setIsLoading, setLoggedUser } = useContext(AppCtx);
  const [emailEligibility, setEmailEligibility] = useState<boolean>(false);
  const [landLordCredentials, setLandLordCredentials] =
    useState<SignUpEligibilityModel>();
  const [isLandlord, setIsLandlord] = useState(true);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<SignUpFormModel>({
    resolver: yupResolver(
      emailEligibility ? SignupFormSchema : EmailEligibilityFormSchema
    ),
    defaultValues: {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
  });

  const submit = async (signupFormData: SignUpFormModel) => {
    setIsLoading(true);
    if (emailEligibility && landLordCredentials) {
      signupFormData.landLordIds = landLordCredentials.landLordIds;
      signupFormData.tenantId = landLordCredentials.tenantId;
      signupFormData.isLandLord = isLandlord;
      let tempLoggedUser = await SignUpUser(signupFormData);
      if (tempLoggedUser) {
        await setLoggedUser(tempLoggedUser);
        if (tempLoggedUser.isLandLord) {
          navigate("/properties");
        } else {
          navigate("/properties/" + tempLoggedUser.tenantPropertyId);
        }
      }
    } else {
      const userCredentials = (await VerifySignUpEligibility(
        signupFormData.email,
        isLandlord
      )) as unknown as SignUpEligibilityModel;

      if (userCredentials) {
        setLandLordCredentials(userCredentials);
        setValue("firstName", userCredentials.firstName);
        setValue("lastName", userCredentials.lastName);
        setEmailEligibility(true);
      }
    }
    setIsLoading(false);
  };

  function handleIsTenantLandLordChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setIsLandlord(event.target.value === "true");
  }

  return (
    <Container component="main" maxWidth="xs">
      <Card variant="outlined" sx={{ borderRadius: 3 }}>
        <CardContent sx={{ p: 3 }}>
          <Typography
            component="div"
            variant="h4"
            fontWeight="bold"
            textAlign="center"
          >
            SIGN UP
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(submit)}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  name="email"
                  label="Email Address"
                  error={errors.email}
                  disabled={emailEligibility}
                  inputProps={{
                    type: "email",
                    autoFocus: !emailEligibility,
                  }}
                />
              </Grid>
              <Grid item>
                <RadioGroup row onChange={handleIsTenantLandLordChange} value={isLandlord}>
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label={"Landlord"}
                    disabled={emailEligibility}
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label={"Tenant"}
                    disabled={emailEligibility}
                  />
                </RadioGroup>
              </Grid>
              {emailEligibility && (
                <>
                  <Grid item xs={12} sm={6}>
                    <ControlledTextField
                      control={control}
                      name="firstName"
                      label="First Name"
                      error={errors.firstName}
                      disabled={emailEligibility}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ControlledTextField
                      control={control}
                      name="lastName"
                      label="Last Name"
                      error={errors.lastName}
                      disabled={emailEligibility}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ControlledTextField
                      control={control}
                      name="userName"
                      label="Username"
                      error={errors.userName}
                      inputProps={{
                        inputMode: "text",
                        autoFocus: emailEligibility,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ControlledTextField
                      control={control}
                      name="password"
                      label="Password"
                      error={errors.password}
                      inputProps={{
                        type: "password",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ControlledTextField
                      control={control}
                      name="confirmPassword"
                      label="Confirm Password"
                      error={errors.confirmPassword}
                      inputProps={{
                        type: "password",
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              size="large"
              sx={{ mt: 3, mb: 2, p: 2 }}
            >
              {isLoading ? (
                <CircularProgress color="inherit" size={26} />
              ) : emailEligibility ? (
                "Sign Up"
              ) : (
                "Verify"
              )}
            </Button>
            <Grid container>
              <Grid item>
                <Link to="/auth/login">Already have an account? Sign in</Link>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

import * as yup from "yup";

export const TenantApplicationSchema = yup.object().shape({
  image: yup.string().required().label("Image"),
  firstName: yup.string().required().label("First name"),
  lastName: yup.string().required().label("Last name"),
  email: yup.string().email().required().label("Email address"),
  phoneNumber: yup.string().required().label("Phone number"),
  property: yup.string().required().label("property"),
  viewingDate: yup.string().required().label("Viewing date"),
  rentAgreedCurrency: yup.string().required().label("Currency"),
  rentAgreedFrequency: yup.string().required().label("Frequency"),
  deposit: yup.string().required().label("Deposit"),
  tenancyFrom: yup.string().required().label("Tenancy from date"),
  tenancyTill: yup.string().required().label("Tenancy till date"),
  annualIncome: yup.string().required().label("Annual income"),
  ccjAdverseHistory: yup.bool().required().label("CCJ/ Adverse history"),
  // ccjInformation
  guarantorRequired: yup.bool().required().label("Guarantor required"),
  additionalInformation: yup
    .string()
    .notRequired()
    .max(100)
    .label("Addition information"),
  amountPaid: yup.string().required().label("Amount paid"),
  dateOfPayment: yup.string().required().label("Date of payment"),
  confirm1: yup.bool().oneOf([true], "You should accept this"),
  confirm2: yup.bool().oneOf([true], "You should confirm this"),
  disclaimerAccepted: yup
    .bool()
    .oneOf([true], "Please read disclaimer and accept them"),
});

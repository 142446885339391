import axios from "axios";
import { showSnackBar } from "../../functions/snackbar";
import LoginFormModel from "../../models/auth/login";
import { userModel } from "../../models/auth/user";

export async function LoginUser(loginFormData: LoginFormModel) {
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  return new Promise<userModel>((resolve) => {
    axios
      .post(BaseUrl + "api/account/signin", loginFormData)
      .then(function (response) {
        if (response.data.status === false) {
          showSnackBar("error", response.data.message);
        } else {
          showSnackBar("success", "Successfully Logged In");
        }
        resolve(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        showSnackBar("error", error.message);
      });
  });
}

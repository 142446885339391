import { CurrencyPound, DateRange, HighlightOff } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { blue, green, pink } from "@mui/material/colors";
import { Container } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getFinancialInformation } from "../../apis/property/financial";
import { SelectProperty } from "../../components/property/selectProperty";
import { AppColors } from "../../constants/colors";
import { convertDate } from "../../functions/modifyText";
import { FinancialInformation } from "../../models/property/financial";
import PropertyModel from "../../models/property/property";
import { AppCtx } from "../../store/store";

export const Financial = () => {
  const navigate = useNavigate();
  const { setIsLoading, getLoggedUser } = useContext(AppCtx);
  const loggedUser = getLoggedUser();
  const { propertyId } = useParams();
  const [financialInfo, setFinancialInfo] = useState<FinancialInformation>();
  const [selectedProperty, setSelectedProperty] = useState<PropertyModel>();

  useEffect(() => {
    async function getFinancialInfo() {
      setIsLoading(true);
      const tempFinancialInfo = (await getFinancialInformation(
        propertyId!,
        loggedUser?.token!
      )) as unknown as FinancialInformation;
      setFinancialInfo(tempFinancialInfo);
      setIsLoading(false);
    }
    if (propertyId && loggedUser) {
      getFinancialInfo();
    }
  }, [propertyId]);

  useEffect(() => {
    selectedProperty &&
      navigate(`/properties/financial/${selectedProperty?.id}`);
  }, [selectedProperty]);

  const handleChangeProperty = (property: PropertyModel) => {
    setSelectedProperty(property);
  };

  return (
    <Container maxWidth={"sm"}>
      <Card variant="outlined" sx={{ borderRadius: 3, mb: 3, p: 2 }}>
        {loggedUser?.isLandLord && (
          <SelectProperty
            selectedPropertyId={propertyId!}
            getSelected={handleChangeProperty}
          />
        )}
        <Card raised>
          <CardHeader
            title={
              <Typography variant="h5" fontWeight={"bold"}>
                Tenant's Current Balance
              </Typography>
            }
          />
          <CardContent sx={{ pt: 0 }}>
            <List>
              <ListItem sx={{ bgcolor: blue[50] }}>
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: pink[500] }}>
                    <CurrencyPound />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      variant="button"
                      color={AppColors.DarkBlue}
                      fontWeight={"bold"}
                      fontSize={30}
                    >
                      {financialInfo?.tenantCurrentBalance
                        ? financialInfo?.tenantCurrentBalance
                        : "0.00"}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card sx={{ mt: 2 }} raised>
          <CardHeader
            title={
              <Typography variant="h5" fontWeight={"bold"}>
                Next Due
              </Typography>
            }
          />
          <CardContent sx={{ pt: 0 }}>
            <List>
              <ListItem sx={{ bgcolor: AppColors.LightGray }}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: green[500] }}>
                    <DateRange />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="button" color={"primary"}>
                      date
                    </Typography>
                  }
                  secondary={
                    <Typography variant="subtitle2" color={"secondary"}>
                      {convertDate(financialInfo?.nextRentDueDate!)}
                    </Typography>
                  }
                />
              </ListItem>
              <Divider sx={{ mt: 1, mb: 1 }} />
              {financialInfo?.nextRentDueList!.map((item, index) => (
                <ListItem
                  sx={{
                    bgcolor: index % 2 !== 0 ? AppColors.LightGray : blue[50],
                  }}
                  key={index}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        bgcolor: index % 2 !== 0 ? green[500] : pink[500],
                        mr: 1,
                      }}
                    >
                      <CurrencyPound />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="button" color={"primary"}>
                        Amount
                      </Typography>
                    }
                    secondary={
                      <Typography variant="subtitle2" color={"secondary"}>
                        {item.amount}
                      </Typography>
                    }
                  />
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle2"
                        color={"primary"}
                        textAlign={"right"}
                      >
                        {item.accHead}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
        <Box display="flex" justifyContent="center">
          <Tooltip title={"Back to property page"}>
            <IconButton
              color="primary"
              onClick={() => navigate("/properties/" + propertyId)}
            >
              <HighlightOff sx={{ fontSize: 60 }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Card>
    </Container>
  );
};

import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Checkbox,
  Dialog,
  FormHelperText,
} from "@mui/material";
import React, { useState } from "react";
import TenantApplicationDisclaimer from "./disclaimer";
import { Controller, FieldErrorsImpl } from "react-hook-form";
import { TenantApplicationModel } from "../../models/forms/tenantApplication";

interface Props {
  control: any;
  firstName: string;
  errors: Partial<FieldErrorsImpl<TenantApplicationModel>>;
  disclaimerAccepted: boolean;
  setDisclaimerAcceted(value: boolean): void;
}

export const TenantApplicationConfirm: React.FC<Props> = (props) => {
  let control = props.control;
  let errors = props.errors;
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  let firstName = props.firstName;
  if (firstName === "") {
    firstName = "---";
  }

  function toggleShowDisclaimer() {
    setShowDisclaimer(!showDisclaimer);
  }
  function acceptDisclaimer(value: boolean) {
    toggleShowDisclaimer();
    props.setDisclaimerAcceted(value);
  }

  return (
    <>
      <List>
        <ListItem>
          <ListItemText>
            <Typography variant="body2" mt={2} textAlign={"justify"}>
              I {firstName} confirm that the information provided in this
              application form is true to the best of my knowledge, and I'm
              happy with the checks which Spectra will complete as detailed
              above. These results may be accessed again if I apply for a
              tenancy in the future.
            </Typography>
          </ListItemText>
          <ListItemAvatar>
            <Controller
              name="confirm1"
              control={control}
              render={({ field }) => <Checkbox {...field} sx={{ ml: 2 }} />}
            />
          </ListItemAvatar>
        </ListItem>
        {errors.confirm1 && (
          <FormHelperText error>{errors.confirm1.message}</FormHelperText>
        )}
        <ListItem>
          <ListItemText>
            <Typography variant="body2" mt={2} textAlign={"justify"}>
              I {firstName} confirm that this application, my credit check
              report and references be shared with the landiord for enabling the
              landlord to. decide and consent to enter into a tenancy agreement
            </Typography>
          </ListItemText>
          <ListItemAvatar>
            <Controller
              name="confirm2"
              control={control}
              render={({ field }) => <Checkbox {...field} sx={{ ml: 2 }} />}
            />
          </ListItemAvatar>
        </ListItem>
        {errors.confirm2 && (
          <FormHelperText error>{errors.confirm2.message}</FormHelperText>
        )}
        <ListItem>
          <ListItemText>
            <Typography variant="body2" textAlign={"justify"}>
              Please read and complete the following statements carefully - It's
              important that you provide your consent for the checks by marking
              the boxes next to the text and then signing and dating below.
            </Typography>
          </ListItemText>
          <ListItemAvatar>
            <Checkbox
              checked={props.disclaimerAccepted}
              sx={{ ml: 2 }}
              onChange={toggleShowDisclaimer}
            />
          </ListItemAvatar>
        </ListItem>
        {errors.disclaimerAccepted && (
          <FormHelperText error>
            {errors.disclaimerAccepted.message}
          </FormHelperText>
        )}
      </List>
      <Dialog onClose={toggleShowDisclaimer} open={showDisclaimer}>
        <TenantApplicationDisclaimer acceptDisclaimer={acceptDisclaimer} />
      </Dialog>
    </>
  );
};

import { Typography, Stack, FormControl, FormHelperText } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { ControlledTextField } from "../shared/controlledTextField";
import { Controller, FieldErrorsImpl } from "react-hook-form";
import { TenantApplicationModel } from "../../models/forms/tenantApplication";

interface Props {
  control: any;
  errors: Partial<FieldErrorsImpl<TenantApplicationModel>>;
}

export const TenantApplicationHoldingDeposit: React.FC<Props> = (props) => {
  let control = props.control;
  let errors = props.errors;

  return (
    <>
      <Typography variant="button">Holding Deposit</Typography>
      <ControlledTextField
        control={control}
        name="amountPaid"
        label="Amount Paid"
        error={errors.amountPaid}
        inputProps={{
          type: "number",
        }}
        style={{ mt: 2, mb: 2 }}
      />
      <Controller
        control={control}
        name="dateOfPayment"
        render={({ field }) => (
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                onChange={(date) => field.onChange(date)}
                label={"Date of payment"}
              />
            </LocalizationProvider>
          </FormControl>
        )}
      />
      {errors.dateOfPayment && (
        <FormHelperText error>{errors.dateOfPayment.message}</FormHelperText>
      )}
    </>
  );
};

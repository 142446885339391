import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";

export default function AuthLayout() {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" elevation={0} sx={{ background: "primary" }}>
          <Box display="flex" alignItems="center" padding={2}>
            <img
              src="/images/logo.png"
              alt="Spectra Invoice Logo"
              width={100}
              style={{ margin: "auto" }}
            />
          </Box>
        </AppBar>
      </Box>
      <div style={{ paddingTop: 20 }}>
        <Outlet />
      </div>
    </>
  );
}

import { Typography, Stack, FormHelperText, FormControl } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { ControlledTextField } from "../shared/controlledTextField";
import { Controller, FieldErrorsImpl } from "react-hook-form";
import { TenantApplicationModel } from "../../models/forms/tenantApplication";
import { ControlledSelectInput } from "../shared/controlledSelectInput";

interface Props {
  control: any;
  errors: Partial<FieldErrorsImpl<TenantApplicationModel>>;
}

export const TenantApplicationPreApplication: React.FC<Props> = (props) => {
  let control = props.control;
  let errors = props.errors;

  return (
    <>
      <Typography variant="button">Pre-Application</Typography>
      <ControlledTextField
        control={control}
        name="property"
        label="Property"
        error={errors.property?.message}
        inputProps={{
          type: "text",
        }}
        style={{ mt: 1, mb: 1 }}
      />
      <Controller
        control={control}
        name="viewingDate"
        render={({ field }) => (
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                onChange={(date) => field.onChange(date)}
                label={" Confirm viewing date"}
              />
            </LocalizationProvider>
          </FormControl>
        )}
      />
      {errors.viewingDate && (
        <FormHelperText error>{errors.viewingDate?.message}</FormHelperText>
      )}
      <Stack direction="row" spacing={1} alignItems="center" mt={1}>
        <Typography variant="subtitle2" width={"100%"}>
          Rent agreed
        </Typography>
        <ControlledTextField
          control={control}
          name="rentAgreedCurrency"
          label="Currency"
          error={errors.rentAgreedCurrency}
          hideHelperText={true}
          inputProps={{
            type: "number",
          }}
        />
        <ControlledTextField
          control={control}
          name="rentAgreedFrequency"
          label="Frequency"
          error={errors.rentAgreedFrequency}
          hideHelperText={true}
          inputProps={{
            type: "number",
          }}
        />
      </Stack>
      {errors.rentAgreedCurrency && (
        <FormHelperText error>
          {errors.rentAgreedCurrency.message}
        </FormHelperText>
      )}
      {errors.rentAgreedFrequency && (
        <FormHelperText error>
          {errors.rentAgreedFrequency.message}
        </FormHelperText>
      )}
      <ControlledTextField
        control={control}
        name="deposit"
        label="Deposit"
        error={errors.deposit}
        inputProps={{
          type: "number",
        }}
        style={{ mt: 1, mb: 2 }}
      />
      <Controller
        control={control}
        name="tenancyFrom"
        render={({ field }) => (
          <FormControl fullWidth error={!!errors.tenancyFrom} sx={{ mb: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                onChange={(date) => field.onChange(date)}
                label={"Tenancy from"}
              />
            </LocalizationProvider>
          </FormControl>
        )}
      />
      {errors.tenancyFrom && (
        <FormHelperText error>{errors.tenancyFrom?.message}</FormHelperText>
      )}
      <Controller
        control={control}
        name="tenancyTill"
        render={({ field }) => (
          <FormControl fullWidth sx={{ mb: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                onChange={(date) => field.onChange(date)}
                label={"Tenancy till"}
              />
            </LocalizationProvider>
          </FormControl>
        )}
      />

      {errors.tenancyTill && (
        <FormHelperText error>{errors.tenancyTill?.message}</FormHelperText>
      )}
      <ControlledTextField
        control={control}
        name="annualIncome"
        label="Annual Income"
        error={errors.annualIncome}
        inputProps={{
          type: "number",
        }}
        style={{ mb: 2 }}
      />
      <ControlledSelectInput
        control={control}
        name="ccjAdverseHistory"
        label="CCJ/ Adverse history"
        selectOptions={[
          { option: "Yes", value: true },
          { option: "No", value: false },
        ]}
        error={errors.ccjAdverseHistory}
      />
      <ControlledTextField
        control={control}
        name="ccjInformation"
        label="CCJ Information"
        error={errors.ccjInformation}
        inputProps={{
          type: "text",
        }}
        style={{ mt: 2, mb: 2 }}
      />
      <ControlledSelectInput
        control={control}
        label="Guarantor Required"
        name="guarantorRequired"
        selectOptions={[
          { option: "Yes", value: true },
          { option: "No", value: false },
        ]}
        error={errors.guarantorRequired}
      />
      <ControlledTextField
        control={control}
        name="additionalInformation"
        label="Additional Information"
        error={errors.additionalInformation}
        inputProps={{
          type: "text",
        }}
        style={{ mt: 2, mb: 4 }}
      />
    </>
  );
};

import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { green, orange, red, yellow } from "@mui/material/colors";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getComplianceCertificates,
  sendComplianceCertificate,
} from "../../apis/property/compliance";
import { CertificateListItem } from "../../components/property/certificatesListItem";
import { SelectProperty } from "../../components/property/selectProperty";
import {
  ComplaincePageModel,
  ComplianceCertificateModel,
  EPCRatingTagModel,
} from "../../models/property/compliance";
import PropertyModel from "../../models/property/property";
import { AppCtx } from "../../store/store";

export const Compliance = () => {
  const navigate = useNavigate();
  const { setIsLoading, getLoggedUser } = useContext(AppCtx);
  const loggedUser = getLoggedUser();
  const { propertyId } = useParams();
  const [certificates, setCertificates] =
    useState<ComplianceCertificateModel[]>();
  const [epcRating, setEpcRating] = useState<EPCRatingTagModel>();
  const [selectedProperty, setSelectedProperty] = useState<PropertyModel>();

  useEffect(() => {
    getCertificates();
  }, [propertyId]);

  useEffect(() => {
    selectedProperty &&
      navigate(`/properties/compliance/${selectedProperty?.id}`);
  }, [selectedProperty]);

  async function getCertificates() {
    if (loggedUser && propertyId) {
      setIsLoading(true);
      const response = (await getComplianceCertificates(
        propertyId!,
        loggedUser?.token!
      )) as unknown as ComplaincePageModel;
      if (response) {
        setCertificates(response.certificates);
        createEPCRatingModel(response.epcRating);
      }
      setIsLoading(false);
    }
  }

  const createEPCRatingModel = (epcRating: number) => {
    let color: string = "";
    let letter: string = "";
    if (epcRating >= 92) {
      letter = "A";
      color = green["A700"];
    } else if (epcRating >= 81) {
      letter = "B";
      color = yellow[500];
    } else if (epcRating >= 69) {
      letter = "C";
      color = green["A400"];
    } else if (epcRating >= 55) {
      letter = "D";
      color = yellow[500];
    } else if (epcRating >= 39) {
      letter = "E";
      color = orange[200];
    } else if (epcRating >= 21) {
      letter = "F";
      color = orange[100];
    } else if (epcRating >= 1) {
      letter = "G";
      color = red[200];
    }
    var epcRatingModel: EPCRatingTagModel = {
      color: color,
      letter: letter,
      rating: epcRating,
    };
    setEpcRating(epcRating ? epcRatingModel : undefined);
  };

  const sendCertificate = async (certificateCode: number) => {
    setIsLoading(true);
    await sendComplianceCertificate(
      certificateCode,
      propertyId!,
      loggedUser?.token!
    );
    setIsLoading(false);
  };
  const handleChangeProperty = (property: PropertyModel) => {
    setSelectedProperty(property);
  };

  return (
    <Container maxWidth="sm">
      <Card variant="outlined" sx={{ borderRadius: 3, mb: 3 }}>
        <CardContent sx={{ p: 3, alignItems: "center" }}>
          {loggedUser?.isLandLord && (
            <SelectProperty
              selectedPropertyId={propertyId!}
              getSelected={handleChangeProperty}
            />
          )}
          <Divider sx={{ mt: 1, mb: 1 }} />
          {certificates && (
            <Grid container mt={2} columns={2}>
              {certificates.map((item, index) => (
                <CertificateListItem
                  certificate={item}
                  key={index}
                  handleSendCertificate={() =>
                    sendCertificate(item.certificateCode)
                  }
                  epcRating={epcRating}
                />
              ))}
            </Grid>
          )}
          <Box display="flex" justifyContent="center">
            <Tooltip title={"Back to property page"}>
              <IconButton
                color="primary"
                onClick={() => navigate("/properties/" + propertyId)}
              >
                <HighlightOff sx={{ fontSize: 60 }} />
              </IconButton>
            </Tooltip>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

import axios from "axios";
import { showSnackBar } from "../../functions/snackbar";

export const VerifyEmailEligibility = async (email: String, isLandLord: boolean) => {
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  var newUserCredentials = undefined;

  await axios
    .get(BaseUrl + "api/account/checkSignupEligibility", {
      params: { email: email, isLandLord: isLandLord },
    })
    .then(function (response) {
      if (!response.data.status) {
        showSnackBar("error", response.data.message);
      } else {
        showSnackBar("success", "Email Verified!");
        newUserCredentials = response.data.data;
      }
    })
    .catch(function (error) {
      console.log(error);
      showSnackBar("error", error.message);
    });
  return newUserCredentials;
};

import { createBrowserRouter, redirect } from "react-router-dom";
import { LocalStorage } from "./functions/localstorage";
import MainLayout from "./layouts/mainLayout";
import Login from "./pages/Auth/login";
import PasswrodRecovery from "./pages/Auth/passwordRecovery";
import SignUp from "./pages/Auth/signup";
import { Properties } from "./pages/Property/properties";
import Property from "./pages/Property/property";
import ErrorPage from "./pages/message/error-page";
import { AccountActivated } from "./pages/message/success";
import { Tenancy } from "./pages/Property/tenancy";
import AuthLayout from "./layouts/authLayout";
import { Compliance } from "./pages/Property/compliance";
import { Financial } from "./pages/Property/financial";
import { TenantApplication } from "./pages/Forms/tenantApplication";

async function CheckRootLoginStatus() {
  var loggedUser = LocalStorage.getLoggedUser();
  var route = null;
  if (loggedUser) {
    if (loggedUser.isLandLord) {
      route = "properties";
    } else {
      route = "properties/" + loggedUser.tenantPropertyId;
    }
  } else {
    route = "/auth/login";
  }
  return route && redirect(route);
}

function CheckAuthLoginStatus() {
  var loggedUser = LocalStorage.getLoggedUser();
  var route = null;
  if (loggedUser) {
    if (loggedUser.isLandLord) {
      route = "properties";
    } else {
      route = "properties/" + loggedUser.tenantPropertyId;
    }
  }
  return route && redirect(route);
}

function CheckPropertyLoginStatus() {
  var loggedUser = LocalStorage.getLoggedUser();
  var route = null;
  if (!loggedUser) {
    route = "/auth/login";
  }
  return route && redirect(route);
}

function CheckPropertiesLoginStatus() {
  var loggedUser = LocalStorage.getLoggedUser();
  var route = null;
  if (!loggedUser.isLandLord) {
    route = loggedUser.tenantPropertyId.toString();
  }
  return route && redirect(route);
}

export const router = createBrowserRouter([
  {
    path: "/",
    loader: CheckRootLoginStatus,
    errorElement: <ErrorPage />,
  },
  {
    path: "/properties",
    element: <MainLayout />,
    loader: CheckPropertyLoginStatus,
    children: [
      {
        path: "/properties/",
        element: <Properties />,
        loader: CheckPropertiesLoginStatus,
      },
      {
        path: "/properties/:propertyId",
        element: <Property />,
      },
      {
        path: "/properties/tenancy/:propertyId",
        element: <Tenancy />,
      },
      {
        path: "/properties/compliance/:propertyId",
        element: <Compliance />,
      },
      {
        path: "/properties/financial/:propertyId",
        element: <Financial />,
      },
    ],
  },
  {
    path: "/auth/",
    element: <AuthLayout />,
    loader: CheckAuthLoginStatus,
    children: [
      {
        path: "/auth/login",
        element: <Login />,
      },
      {
        path: "/auth/signup",
        element: <SignUp />,
      },
      {
        path: "/auth/password-recovery",
        element: <PasswrodRecovery />,
      },
    ],
  },
  {
    path: "/forms",
    element: <AuthLayout />,
    children: [
      {
        path: "/forms/tenantApplication",
        element: <TenantApplication />,
      },
    ],
  },
  {
    path: "/message/success",
    element: <AccountActivated />,
  },
]);

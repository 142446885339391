import { TypeOptions } from "react-toastify/dist/types";
import { toast } from "react-toastify";

export const showSnackBar = (variant: TypeOptions, message: String) => {
  toast(message, {
    type: variant,
    hideProgressBar: true,
    theme: "colored",
  });
};

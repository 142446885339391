import axios from "axios";
import { logoutUser } from "../../functions/logout";
import { showSnackBar } from "../../functions/snackbar";

export const getFinancialInformation = async (
  propertyId: string,
  token: string
) => {
  var finacialInformation;
  await axios
    .get(
      process.env.REACT_APP_API_BASE_URL +
        "api/property/getFinancialInformation",
      {
        params: {
          propertyId: propertyId,
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((response) => {
      if (response.data.status) {
        finacialInformation = response.data.data;
      } else {
        showSnackBar("error", response.data.message);
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        logoutUser();
        showSnackBar("error", "Your session has expired, please log again.");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        showSnackBar("error", error.message);
      }
    });
  return finacialInformation;
};

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { SelectOptionModel } from "../../models/shared/selectOption";

type Props = {
  name: string;
  label: string;
  control: any;
  selectOptions?: SelectOptionModel[];
  error?: any;
  style?: SxProps;
};
export const ControlledSelectInput = (props: Props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => {
        return (
          <FormControl sx={props.style} fullWidth>
            <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
            <Select
              id={props.name}
              label={props.label}
              {...field}
              error={props.error}
            >
              {props.selectOptions!.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.option}
                </MenuItem>
              ))}
            </Select>
            {props.error && (
              <FormHelperText error>{props.error.message}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};

import * as yup from "yup";

export const SignupFormSchema = yup.object().shape({
  firstName: yup.string().required().label("First Name"),
  lastName: yup.string().required().label("Last Name"),
  userName: yup.string().required().min(5).max(20).label("Username"),
  email: yup.string().email().required().label("Email"),
  password: yup.string().min(8).max(20).required().label("Password"),
  confirmPassword: yup
    .string()
    .min(8)
    .max(20)
    .required()
    .label("Confirm Password")
    .oneOf([yup.ref("password"), null], "Password doesn't match"),
});

import axios from "axios";
import { showSnackBar } from "../../functions/snackbar";

export const RecoverPassword = async (email: String) => {
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  var successMessage: string = "";

  await axios
    .get(BaseUrl + "api/account/recoverPassword", {
      params: { email: email },
    })
    .then(function (response) {
      if (response.data.status === false) {
        showSnackBar("error", response.data.message);
      } else {
        showSnackBar("success", response.data.message);
        successMessage = response.data.message;
      }
    })
    .catch(function (error) {
      console.log(error);
      showSnackBar("error", error.message);
    });
  return successMessage;
};

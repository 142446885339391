import { Logout } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { AppCtx } from "../store/store";
import { logoutUser } from "../functions/logout";

export default function MainLayout() {
  const { setIsLoading } = useContext(AppCtx);

  const logout = async () => {
    setIsLoading(true);
    await logoutUser();
    setIsLoading(false);
    window.location.reload();
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" elevation={0} sx={{ background: "primary" }}>
          <Box display="flex" alignItems="center" padding={2}>
            <img
              src="/images/logo.png"
              alt="Spectra Invoice Logo"
              width={100}
              style={{ margin: "auto" }}
            />
            <IconButton size="medium" onClick={logout} color="inherit">
              <Logout fontSize="medium" />
            </IconButton>
          </Box>
        </AppBar>
      </Box>
      <div style={{ paddingTop: 20 }}>
        <Outlet />
      </div>
    </>
  );
}

import { AppColors } from "../constants/colors";
import PropertyModel from "../models/property/property";

export const choosePropertyColor = (property: PropertyModel) => {
  var bgColor = AppColors.DarkBlue;
  if (property.isWithdrawn) {
    bgColor = AppColors.DarkGray;
  } else if (property.isCertificateExpired) {
    bgColor = AppColors.DarkRed;
  } else if (!property.hasTenants) {
    bgColor = AppColors.LightBlue;
  }
  return bgColor;
};

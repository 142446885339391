import { useContext } from "react";
import {
  Backdrop,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { RouterProvider } from "react-router-dom";
import { AppCtx } from "./store/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { router } from "./router";
import { AppColors } from "./constants/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: AppColors.DarkBlue,
    },
    secondary: {
      main: AppColors.DarkYellow,
    },
  }
});

export default function APP() {
  const { isLoading } = useContext(AppCtx);

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <Backdrop sx={{ color: AppColors.White, zIndex: 999999999 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

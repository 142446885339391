import { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Card, CardContent, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PasswordRecoverySchema } from "../../schemas/forms/passwordRecovery";
import PasswordRecoveryModel from "../../models/auth/passwordRecovery";
import { ControlledTextField } from "../../components/shared/controlledTextField";
import { Link } from "react-router-dom";
import { AppCtx } from "../../store/store";
import { RecoverPassword } from "../../apis/auth/passwordRecover";

export default function PasswrodRecovery() {
  const { isLoading, setIsLoading } = useContext(AppCtx);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PasswordRecoveryModel>({
    resolver: yupResolver(PasswordRecoverySchema),
    defaultValues: {
      email: "",
    },
  });

  const submit = async (data: PasswordRecoveryModel) => {
    setIsLoading(true);
    setSuccessMessage(await RecoverPassword(data.email));
    setIsLoading(false);
  };

  return (
    <Container component="main" maxWidth={successMessage !== "" ? "md" : "xs"}>
      <Card variant="outlined" sx={{ borderRadius: 3 }}>
        <CardContent sx={{ p: 3 }}>
          <Typography
            component="div"
            variant="h5"
            fontWeight="bold"
            textAlign="center"
          >
            RECOVER PASSWORD
          </Typography>
          {successMessage !== "" ? (
            <>
              <Typography
                component="div"
                variant="h5"
                fontWeight="bold"
                textAlign="center"
                color="green"
                sx={{ py: 5 }}
              >
                {successMessage}
              </Typography>
              <center>
                <Link to="/auth/login">Back to login page</Link>
              </center>
            </>
          ) : (
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(submit)}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ControlledTextField
                    control={control}
                    name="email"
                    label="Email Address"
                    error={errors.email}
                    inputProps={{
                      type: "email",
                    }}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disableElevation
                size="large"
                sx={{ mt: 3, mb: 2, p: 2 }}
              >
                {isLoading ? (
                  <CircularProgress color="inherit" size={26} />
                ) : (
                  "SUBMIT"
                )}
              </Button>
              <Link to="/auth/signup">Don't have an account? Sign up</Link>
            </Box>
          )}
        </CardContent>
      </Card>
    </Container>
  );
}

export enum AppColors {
  LightGray = "#f5f5f594",
  Gray = "#bdbdbd",
  DarkGray = "#A9A9A9",
  DarkRed = "#880808",
  SoftOrange = "#FAD5A5",
  LightBlue = "#5AC4F6",
  DarkYellow = "#D68021",
  DarkBlue = "#2E3170",
  White = "#FFF",
}

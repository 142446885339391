import axios from "axios";
import { logoutUser } from "../../functions/logout";
import { showSnackBar } from "../../functions/snackbar";

const BaseUrl = process.env.REACT_APP_API_BASE_URL;

export const getProperties = async (pageNumber = 0, token: string) => {
  var properties;
  await axios
    .get(BaseUrl + "api/property/getProperties", {
      params: {
        pageNumber: pageNumber,
      },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      if (response.data.status) {
        properties = response.data.data;
      } else {
        showSnackBar("error", response.data.message);
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        logoutUser();
        showSnackBar("error", "Your session has expired, please log again.");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        showSnackBar("error", error.message);
      }
    });
  return properties;
};

export const getPropertiesPagination = async () => {
  var totalPages = 0;
  await axios
    .get(BaseUrl + "api/property/getPropertiesPagination")
    .then((response) => {
      totalPages = response.data;
    })
    .catch(() => {});
  return totalPages;
};

import axios from "axios";
import { logoutUser } from "../../functions/logout";
import { showSnackBar } from "../../functions/snackbar";

const BaseUrl = process.env.REACT_APP_API_BASE_URL;

export const getComplianceCertificates = async (propertyId: string, token: string) => {
  var certificates;

  await axios
    .get(BaseUrl + "api/property/getComplianceCertificates", {
      params: {
        propertyId: propertyId,
      },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      if (response.data.status === false) {
        showSnackBar("error", response.data.message);
      } else {
        certificates = response.data.data;
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        logoutUser();
        showSnackBar("error", "Your session has expired, please log again.");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      else{
        showSnackBar("error", error.message);
      }
    });

  return certificates;
};

export const sendComplianceCertificate = async (
  certificateCode: number,
  propertyId: string,
  token: string
) => {
  var response;

  await axios
    .get(BaseUrl + "api/property/sendComplianceCertificate", {
      params: {
        certificateCode: certificateCode,
        propertyId: propertyId
      },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      showSnackBar(
        response.data.status ? "success" : "error",
        response.data.message
      );
    })
    .catch(function (error) {
      console.log(error);
      showSnackBar("error", error.message);
    });

  return response;
};

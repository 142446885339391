import {
  Avatar,
  Typography,
  Stack,
  IconButton,
  Badge,
  Box,
  FormHelperText,
} from "@mui/material";
import React, { useState } from "react";
import { ControlledTextField } from "../shared/controlledTextField";
import { PhotoCamera } from "@mui/icons-material";
import { TenantApplicationModel } from "../../models/forms/tenantApplication";
import {
  Controller,
  ControllerRenderProps,
  FieldErrorsImpl,
  FieldValues,
} from "react-hook-form";
import { ControlledSelectInput } from "../shared/controlledSelectInput";

interface Props {
  control: any;
  errors: Partial<FieldErrorsImpl<TenantApplicationModel>>;
}

export const TenantApplicationTenantDetails: React.FC<Props> = (props) => {
  let control = props.control;
  let errors = props.errors;
  const [imageUrl, setImageUrl] = useState("");

  function handleSelectImage(
    event: React.ChangeEvent<HTMLInputElement>,
    field: ControllerRenderProps<FieldValues, "image">
  ) {
    let imageFile = event.target.files![0];
    let tempImageURl = URL.createObjectURL(imageFile);
    setImageUrl(tempImageURl);
    field.onChange(event);
  }

  return (
    <>
      <Box ml={"auto"} width={"max-content"}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <Controller
                control={control}
                name="image"
                render={({ field }) => (
                  <input
                    onChange={(e) => handleSelectImage(e, field)}
                    hidden
                    accept="image/*"
                    type="file"
                  />
                )}
              />
              <PhotoCamera />
            </IconButton>
          }
        >
          <Avatar
            src={imageUrl}
            sx={{
              width: "150px",
              height: "150px",
              objectFit: "contain",
            }}
            variant="square"
          />
        </Badge>
      </Box>
      {errors.image && (
        <FormHelperText error>{errors.image?.message}</FormHelperText>
      )}
      <Typography variant="button">Tenant</Typography>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <ControlledSelectInput
          style={{ width: "80%" }}
          name="title"
          label="Title"
          selectOptions={[
            { option: "Mr", value: "Mr" },
            { option: "Mrs", value: "Mrs" },
            { option: "Miss", value: "Miss" },
            { option: "Rev", value: "Rev" },
          ]}
          control={control}
        />
        <ControlledTextField
          control={control}
          name="firstName"
          label="First Name"
          inputProps={{
            type: "text",
          }}
          error={errors.firstName}
          hideHelperText={true}
        />
        <ControlledTextField
          control={control}
          name="middleName"
          label="Middle Name"
          inputProps={{
            type: "text",
          }}
          hideHelperText={true}
        />
        <ControlledTextField
          control={control}
          name="lastName"
          label="Last Name"
          error={errors.lastName}
          inputProps={{
            type: "text",
          }}
          hideHelperText={true}
        />
      </Stack>
      <FormHelperText error>{errors.firstName?.message}</FormHelperText>
      <FormHelperText error>{errors.lastName?.message}</FormHelperText>
      <ControlledTextField
        control={control}
        name="email"
        label="Email"
        error={errors.email}
        inputProps={{
          type: "email",
        }}
        style={{ mt: 1 }}
      />
      <ControlledTextField
        control={control}
        name="phoneNumber"
        label="Phone Number"
        error={errors.phoneNumber}
        inputProps={{
          type: "number",
        }}
        style={{ mt: 1, mb: 4 }}
      />
    </>
  );
};

export function GetShortenText(text: string){
    if (text.length > 20) {
      text = text.substring(0, 20) + "...";
    }
    return text;
}

export const convertDate = (date: Date) => {
  if (date) {
    return new Date(date).toLocaleDateString("en-us", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
};
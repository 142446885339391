import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { TenantApplicationConfirm } from "../../components/TenantApplication/confirm";
import { TenantApplicationHoldingDeposit } from "../../components/TenantApplication/holdingDeposit";
import { TenantApplicationPreApplication } from "../../components/TenantApplication/preApplication";
import { TenantApplicationTenantDetails } from "../../components/TenantApplication/tenantDetails";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { TenantApplicationSchema } from "../../schemas/forms/tenantApplication";
import { AppCtx } from "../../store/store";
import { TenantApplicationModel } from "../../models/forms/tenantApplication";

export const TenantApplication: React.FC = () => {
  const { isLoading, setIsLoading } = useContext(AppCtx);
  const [showResetAlert, setShowResetAlert] = useState(false);
  const {
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm<TenantApplicationModel>({
    resolver: yupResolver(TenantApplicationSchema),
    defaultValues: {
      image: "",
      title: "",
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      property: "",
      viewingDate: "",
      rentAgreedCurrency: "",
      rentAgreedFrequency: "",
      deposit: "",
      tenancyFrom: "",
      tenancyTill: "",
      annualIncome: "",
      ccjAdverseHistory: false,
      ccjInformation: "",
      guarantorRequired: false,
      additionalInformation: "",
      amountPaid: "",
      dateOfPayment: "",
      confirm1: false,
      confirm2: false,
      disclaimerAccepted: false,
    },
  });
  const firstName = watch("firstName");
  const disclaimerAccepted = watch("disclaimerAccepted");

  const submit = async (tenantApplication: TenantApplicationModel) => {
    // setIsLoading(true);
    // setIsLoading(false);
    console.log(tenantApplication);
  };
  function setDisclaimerAccepted(value: boolean) {
    setValue("disclaimerAccepted", value);
  }
  function toggleResetAlert() {
    setShowResetAlert(!showResetAlert);
  }
  function handleResetForm() {
    reset();
    toggleResetAlert();
  }

  return (
    <Container maxWidth={"sm"}>
      <Card variant="outlined" sx={{ borderRadius: 3, mb: 2 }}>
        <CardContent sx={{ p: 3 }}>
          <Typography variant="caption">
            This is applicant registration and pre-application information
          </Typography>
          <Box component={"form"} noValidate onSubmit={handleSubmit(submit)}>
            <TenantApplicationTenantDetails control={control} errors={errors} />
            <TenantApplicationPreApplication
              control={control}
              errors={errors}
            />
            <TenantApplicationHoldingDeposit
              control={control}
              errors={errors}
            />
            <TenantApplicationConfirm
              control={control}
              firstName={firstName}
              errors={errors}
              disclaimerAccepted={disclaimerAccepted}
              setDisclaimerAcceted={setDisclaimerAccepted}
            />
            <Button
              variant="outlined"
              color="warning"
              onClick={toggleResetAlert}
            >
              Reset Form
            </Button>
            <Button type="submit" variant="contained" sx={{ ml: 2 }}>
              Submit Application
            </Button>
            <Dialog open={showResetAlert} onClose={toggleResetAlert}>
              <DialogTitle>Warning</DialogTitle>
              <DialogContent>
                Are you sure want to reset the form?
              </DialogContent>
              <DialogActions>
                <Button onClick={handleResetForm}>Yes</Button>
                <Button onClick={toggleResetAlert}>No</Button>
              </DialogActions>
            </Dialog>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

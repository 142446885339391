import {
  AirlineStops,
  Alarm,
  Assessment,
  Build,
  GasMeter,
  Mail,
  Power,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { blue, orange, pink, red } from "@mui/material/colors";
import React from "react";
import { AppColors } from "../../constants/colors";
import {
  ComplianceCertificateEnums,
  ComplianceCertificates,
} from "../../constants/complianceCertificates";
import { convertDate } from "../../functions/modifyText";
import {
  ComplianceCertificateModel,
  EPCRatingTagModel,
} from "../../models/property/compliance";

interface Props {
  certificate: ComplianceCertificateModel;
  handleSendCertificate(): Promise<void>;
  epcRating?: EPCRatingTagModel;
}
const certificateIcons = [
  <GasMeter />,
  <AirlineStops />,
  <Power />,
  <Build />,
  <AirlineStops />,
  <Assessment />,
  <Alarm />,
];

export const CertificateListItem: React.FC<Props> = (props) => {
  let certificateItem = props.certificate;
  let epcRatingModel = props.epcRating;

  return (
    <Grid item width={"100%"}>
      <ListItem
        sx={{
          background: certificateItem.expiryDate
            ? certificateItem.isExpired
              ? red[50]
              : blue[50]
            : "#f5f5f594",
        }}
      >
        <ListItemAvatar>
          <Avatar
            sx={{
              bgcolor: certificateItem.expiryDate
                ? certificateItem.isExpired
                  ? pink[500]
                  : orange[500]
                : AppColors.Gray,
            }}
          >
            {certificateIcons[certificateItem.certificateCode]}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                variant="button"
                color={!certificateItem.expiryDate ? AppColors.Gray : undefined}
                marginRight={3.6}
              >
                {ComplianceCertificates[certificateItem.certificateCode]}
              </Typography>
              {certificateItem.certificateCode ===
                ComplianceCertificateEnums.EPC &&
                epcRatingModel && (
                  <Typography
                    variant="button"
                    fontSize={15}
                    bgcolor={epcRatingModel?.color}
                    padding={"1% 3%"}
                  >
                    {`${epcRatingModel?.letter} | ${epcRatingModel?.rating}`}
                  </Typography>
                )}
            </React.Fragment>
          }
          secondary={
            <Typography
              variant="subtitle2"
              color={!certificateItem.expiryDate ? AppColors.Gray : undefined}
            >
              {certificateItem.expiryDate
                ? convertDate(certificateItem.expiryDate)
                : "Not Available"}
            </Typography>
          }
        />

        {certificateItem.expiryDate && certificateItem.isExpired && (
          <Box
            component={"img"}
            src="/images/expired.png"
            alt="Expired"
            width={"20%"}
            mr={"5%"}
            sx={{ transform: "rotate(15deg)" }}
            title={"This certificate is Expired!"}
          />
        )}
        <IconButton
          disabled={!certificateItem.expiryDate}
          onClick={props.handleSendCertificate}
        >
          <Tooltip
            title={
              "Send " +
              ComplianceCertificates[certificateItem.certificateCode] +
              " via email."
            }
          >
            <Mail color={certificateItem.expiryDate ? "primary" : "disabled"} />
          </Tooltip>
        </IconButton>
      </ListItem>
      <Divider sx={{ mt: 1, mb: 1 }} />
    </Grid>
  );
};

import { useContext } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Card, CardContent, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginSchema } from "../../schemas/forms/login";
import LoginFormModel from "../../models/auth/login";
import { ControlledTextField } from "../../components/shared/controlledTextField";
import { Link, useNavigate } from "react-router-dom";
import { AppCtx } from "../../store/store";
import { LoginUser } from "../../apis/auth/login";

export default function Login() {
  const { isLoading, setIsLoading, setLoggedUser } = useContext(AppCtx);
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginFormModel>({
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const submit = async (loginFormData: LoginFormModel) => {
    setIsLoading(true);
    let tempLoggedUser = await LoginUser(loginFormData);
    if (tempLoggedUser) {
      await setLoggedUser(tempLoggedUser);
      if (tempLoggedUser.isLandLord) {
        navigate("/properties");
      } else {
        navigate("/properties/" + tempLoggedUser.tenantPropertyId);
      }
    }
    setIsLoading(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Card variant="outlined" sx={{ borderRadius: 3 }}>
        <CardContent sx={{ p: 3 }}>
          <Typography
            component="div"
            variant="h4"
            fontWeight="bold"
            textAlign="center"
          >
            LOGIN
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(submit)}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  name="email"
                  label="Email Address"
                  error={errors.email}
                  inputProps={{
                    type: "email",
                    autoFocus: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  name="password"
                  label="Password"
                  error={errors.password}
                  inputProps={{
                    type: "password",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Link to="/auth/password-recovery">Forgot Password?</Link>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              size="large"
              sx={{ mt: 3, mb: 2, p: 2 }}
            >
              {isLoading ? (
                <CircularProgress color="inherit" size={26} />
              ) : (
                "LOGIN"
              )}
            </Button>
            <Grid container>
              <Grid item>
                <Link to="/auth/signup">Don't have an account? Sign up</Link>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item>
                <Link to="/forms/tenantApplication">Tenant Application</Link>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

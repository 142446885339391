import { useContext, useEffect } from "react";
import { Button, Container, Grid, Pagination, Typography } from "@mui/material";
import {
  getProperties,
  getPropertiesPagination,
} from "../../apis/property/properties";
import { useNavigate } from "react-router-dom";
import { AppCtx } from "../../store/store";
import { choosePropertyColor } from "../../functions/property";
import { AppColors } from "../../constants/colors";
import { Stack } from "@mui/system";
import PropertyModel, {
  PropertiesPagination,
} from "../../models/property/property";
import { yellow } from "@mui/material/colors";

export const Properties = () => {
  const navigate = useNavigate();
  const {
    propertiesList,
    setPropertiesList,
    getLoggedUser,
    setIsLoading,
    propertiesPagination,
    setPropertiesPagination,
  } = useContext(AppCtx);
  const loggedUser = getLoggedUser();
  //const adminEmail = process.env.REACT_APP_ADMIN_EMAIL;

  useEffect(() => {
    if (loggedUser) {
      if (loggedUser.isSuperAdmin && propertiesPagination.totalPages === 0) {
        getTotalPages();
      }
      // This is for non super admin - need to fetch properties without pagination
      else if (!loggedUser.isSuperAdmin && propertiesList.length === 0) {
        fetchProperties();
      }
    }
  }, []);

  useEffect(() => {
    if (loggedUser && propertiesPagination.currentPage > 0) {
      fetchProperties();
    }
  }, [propertiesPagination]);

  async function getTotalPages() {
    setIsLoading(true);
    let tempTotalPages = await getPropertiesPagination();
    if (tempTotalPages) {
      setPropertiesPagination({
        totalPages: tempTotalPages,
        currentPage: 1,
      });
    }
    setIsLoading(false);
  }

  async function fetchProperties() {
    setIsLoading(true);
    const tempProperties = (await getProperties(
      propertiesPagination.currentPage - 1,
      loggedUser?.token!
    )) as unknown as PropertyModel[];
    if (tempProperties) {
      setPropertiesList(tempProperties);
    }
    setIsLoading(false);
  }

  const handlePropertyClick = (propertyId: number) => {
    navigate(propertyId.toString());
  };

  // const openInTeams = () => {
  //   window.open("MSTeams:/l/chat/0/0?users=" + adminEmail, "_blank");
  // };
  function handlePagination(_e: any, page: number) {
    let tempPropertiesPagination: PropertiesPagination = {
      ...propertiesPagination,
      currentPage: page,
    };
    setPropertiesPagination(tempPropertiesPagination);
  }

  return (
    <Container maxWidth="lg">
      <Stack direction={"row"} alignItems={"center"} mb={2}>
        {loggedUser && (
          <Typography variant="h5" fontWeight="bold">
            Welcome -{" "}
            {loggedUser.isSuperAdmin ? "SUPER ADMIN" : loggedUser.name}
          </Typography>
        )}
        {/* <Tooltip title={"Contact Admin."}>
          <IconButton
            sx={{ marginLeft: "auto", display: "block" }}
            onClick={openInTeams}
          >
            <Avatar
              src="/images/ms-teams.png"
              sx={{
                objectFit: "contain",
                width: "40px",
                height: "40px"
              }}
            />
          </IconButton>
        </Tooltip> */}
      </Stack>
      <Grid container spacing={2} marginBottom={5} alignItems={"center"}>
        {propertiesList &&
          propertiesList.map((property, index) => (
            <Grid item xs={12} md={5} lg={3} key={index}>
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  height: 150,
                  backgroundColor: choosePropertyColor(property),
                  "&:hover": {
                    backgroundColor:
                      !property.isWithdrawn &&
                      !property.isCertificateExpired &&
                      !property.hasTenants
                        ? AppColors.LightBlue
                        : AppColors.DarkYellow,
                  },
                  color: property.tenantCurrentBalance < 0 ? yellow[500] : "#fff"
                }}
                onClick={() => handlePropertyClick(property.id)}
                disabled={property.isWithdrawn}
              >
                <Typography variant="h6">{property.name}</Typography>
              </Button>
            </Grid>
          ))}
      </Grid>
      {loggedUser?.isSuperAdmin && (
        <Pagination
          sx={{ mb: 4 }}
          page={propertiesPagination.currentPage}
          count={propertiesPagination.totalPages}
          shape="rounded"
          onChange={handlePagination}
        />
      )}
    </Container>
  );
};
export default Properties;

export const ComplianceCertificates = [
  "Gas Safty",
  "EPC",
  "EICR",
  "Building Insurance",
  "HMO License",
  "Legionella Risk Assessment",
  "Smoke & Co Alarm",
];

export enum ComplianceCertificateEnums {
  GasSafty,
  EPC,
  EICR,
  BuildingInsurance,
  HMOLicense,
  LegionellaRiskAssessment,
  SmokeAndCoAlarm,
}

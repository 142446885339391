import { createContext, useState } from "react";
import { LocalStorage } from "../functions/localstorage";
import { userModel } from "../models/auth/user";
import PropertyModel, {
  PropertiesPagination,
} from "../models/property/property";

export interface AppContextInterface {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  propertiesList: PropertyModel[];
  setPropertiesList(propertiesList: PropertyModel[]): void;
  propertiesPagination: PropertiesPagination;
  setPropertiesPagination: (value: PropertiesPagination) => void;
  getLoggedUser: () => userModel | null;
  setLoggedUser(user: userModel | null): Promise<void>;
}

interface Props {
  children: React.ReactNode;
}

export const AppCtx = createContext<AppContextInterface>({
  isLoading: false,
  setIsLoading: () => {},
  propertiesList: [],
  setPropertiesList: () => {},
  propertiesPagination: { totalPages: 0, currentPage: 0 },
  setPropertiesPagination: () => {},
  getLoggedUser: () => {
    return null;
  },
  setLoggedUser: async () => {},
});

const CtxProvider: React.FC<Props> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [propertiesList, setPropertiesList] = useState<PropertyModel[]>([]);
  const [propertiesPagination, setPropertiesPagination] =
    useState<PropertiesPagination>({ totalPages: 0, currentPage: 0 });

  return (
    <AppCtx.Provider
      value={{
        isLoading,
        setIsLoading,
        propertiesList,
        setPropertiesList,
        propertiesPagination,
        setPropertiesPagination,
        getLoggedUser: LocalStorage.getLoggedUser,
        setLoggedUser: LocalStorage.setLoggedUser,
      }}
    >
      {children}
    </AppCtx.Provider>
  );
};

export default CtxProvider;

import { userModel } from "../models/auth/user";

export class LocalStorage {
  static async setLoggedUser(user: userModel) {
    await localStorage.setItem("user", JSON.stringify(user));
  }

  static getLoggedUser() {
    let user = localStorage.getItem("user")!;
    return JSON.parse(user) as userModel;
  }

  static async clearStorage() {
    await localStorage.clear();
  }
}
